.nl_body {margin-right: auto;margin-left: auto;    }
.nl_first-div {margin-right: auto;margin-left: auto;            }
.nl_container {display:block;        }
.nl_link {text-align:center;      }
.nl_h1 {color: #0073b5;text-align:center;    }
.nl_h2 {color: #0073b5;text-align:center;    }
.nl_info {width:500px;text-align:center;margin-right: auto;margin-left: auto;    }
.nl_h3 {color: #0073b5;text-align:center;    }
.nl_h4 {margin:0;color: black;text-align:center;    }
.nl_p {margin:0;color: black;text-align: center;    }
.nl_menuitem{ margin-bottom: 1rem;}
.nl_message {color: black;text-align: center;margin: 2rem auto; max-width: 90%; word-wrap: break-word;   }
.nl_menu-section {width: 400px;margin-right: auto;margin-left: auto;    }
.nl_oldprice {text-decoration: line-through;    }
.nl_newprice {color:red;    }
.nl_img {    width: 100%;}
.nl_img img {    width: 90%;    object-fit: contain;    margin-right: auto;margin-left: auto;    text-align: center;    display:block;   }